// export const Global = {
//   apiUrl: "https://wt-jcb-back.devsoftmotion.ge/api/",
// };

// export const ProjectTitle = "JCB";

export const Global = {
  // apiUrl: process.env.REACT_APP_API_URL || "https://back.jcb.ge/api/",
  apiUrl: "https://back.jcb.ge/api/",
};

export const ProjectTitle = "JCB";
// export const ProjectTitle = process.env.REACT_APP_PROJECT_TITLE || "JCB";
